//
// Theme style
//

// Initialize
@import "init";

// Components
@import "./core/components/components";
@import "components/components";

// Layout
@import "./core/layout/base/layout";
@import "layout/layout";
.dashboard-wrapper .main-card {
    background: #ffffff; /* Fondo blanco para el card principal */
    border-radius: 20px; /* Bordes redondeados */
    box-shadow: 0 8px 30px rgba(0,0,0,.12); /* Sombra más suave y dispersa */
    border: none; /* Eliminar el borde para un look más limpio */
    padding: 20px; /* Espaciado interno para no tocar los bordes directamente */
}
  
.child-card {
    background: linear-gradient(145deg, #4a90e2, #6aa6e9); /* Gradiente ajustado al azul del logo */
    border-radius: 15px; /* Bordes redondeados para los cards hijos */
    box-shadow: 0 4px 20px rgba(0,0,0,.1); /* Sombra suave y elegante */
    border: none; /* Sin bordes para mantener la suavidad */
    transition: transform 0.3s ease; /* Transición suave al pasar el mouse */
  }
  
  .child-card:hover {
    transform: translateY(-5px); /* Efecto 'levitar' al pasar el mouse */
  }
  
.icon-style {
    font-size: 24px;
    margin-bottom: 10px;
    color: #000000 !important; /* Forzando el color a negro */
}
  
.card-title {
    color: #333; /* Texto oscuro para contraste */
    font-weight: 600; /* Más peso en el título para destacar */
}
  
/* Se sugiere añadir un poco de espacio al final del contenedor principal para evitar que el contenido toque los bordes de la pantalla */
.dashboard-wrapper {
    margin-bottom: 20px;
}


.evaluaciones-table {
    width: 100%;
    background-color: #f0faff; /* Un fondo más claro para no ser tan tenue */
    color: #000; /* Texto en negro para mayor contraste */
    border-collapse: collapse; /* Para unir los bordes */
  }
  
  .evaluaciones-table th, .evaluaciones-table td {
    text-align: center; /* Alineación de texto */
    padding: 8px; /* Espaciado interno */
  }
  
  .evaluaciones-table th {
    background-color: #007bff; /* Un azul más vibrante para los encabezados */
    color: white; /* Texto blanco en los encabezados */
    border-bottom: 2px solid #ddd; /* Borde más definido para los encabezados */
  }
  
  .evaluaciones-table td {
    border-bottom: 1px solid #ddd; /* Borde sutil para las celdas */
  }
  
  .evaluaciones-table tr:hover {
    background-color: #e7f4fe; /* Efecto hover suave */
  }
  